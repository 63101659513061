import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getPrices: {
      metadata: {
        openApiTags: ['Getters'],
      },
      summary: '/ get-prices',
      description: 'Gets the prices available for subscription or purchase',
      method: 'GET',
      path: '/get-prices',
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.array(
              z.object({
                id: z.string().uuid(),
                membership_type: z.string().nullable(),
                membership_period: z.string().nullable(),
                purchase_type: z.string(),
                num_credits: z.number(),
                max_history: z.number(),
                price: z.number(),
                currency: z.string(),
                stripe_prod_buy_url: z.string(),
                stripe_prod_product_id: z.string(),
                stripe_prod_price_id: z.string(),
                stripe_test_buy_url: z.string(),
                stripe_test_product_id: z.string(),
                stripe_test_price_id: z.string(),
                updated_at: z.date(),
                created_at: z.date(),
              }),
            ),
          })
          .openapi({
            title: 'Get Prices',
            description: 'Get the available prices',
          }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
