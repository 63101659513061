import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    status: {
      metadata: {
        openApiTags: ['Utilities'],
        openApiSecurity: [{ BearerAuth: [] }],
      },
      summary: ' / status',
      description: 'Serves as a health check or heartbeat to check that the API is still responding',
      method: 'GET',
      path: '/status',
      headers: z.object({
        authorization: z.string(),
      }),
      responses: {
        200: z
          .object({
            ack: z.number(),
          })
          .openapi({
            title: 'Status',
            description: 'API status',
          }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
