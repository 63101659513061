import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getActivePublicCoupons: {
      metadata: {
        openApiTags: ['Getters'],
      },
      summary: '/ get-active-public-coupons',
      description: 'Gets the coupons that are currently active and available to the public.',
      method: 'GET',
      path: '/get-active-public-coupons',
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.array(
              z.object({
                id: z.string().uuid(),
                stripe_coupon_id: z.string(),
                stripe_promo_id: z.string().nullable(),
                name: z.string(),
                promotion_code: z.string().nullable(),
                is_active: z.boolean(),
                is_public: z.boolean(),
                amount_off: z.number().nullable(),
                percent_off: z.string().transform(Number).nullable(),
                currency: z.string().nullable(),
                duration: z.string(),
                duration_in_months: z.number().nullable(),
                max_redemptions: z.number().nullable(),
                expires_at: z.date().nullable(),
                times_redeemed: z.number(),
                first_time_transaction_only: z.boolean(),
                minimum_purchase_price: z.number().nullable(),
                minimum_purchase_price_currency: z.string().nullable(),
                updated_at: z.date(),
                created_at: z.date(),
              }),
            ),
          })
          .openapi({
            title: 'Get Active Public Coupons',
            description: 'Get the currently active and public coupons for everyone that are still valid',
          }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
