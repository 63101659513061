import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getCreditsUsedInCurrentBillingPeriod: {
      metadata: {
        openApiTags: ['Getters'],
        openApiSecurity: [{ BearerAuth: [] }],
      },
      summary: '/ get-credits-used-in-current-billing-period',
      description: 'Gets the credits used in the current billing period by the user making the request',
      method: 'GET',
      path: '/get-credits-used-in-current-billing-period',
      headers: z.object({
        authorization: z.string(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              numCreditsUsed: z.number(),
            }),
          })
          .openapi({
            title: 'Get Credits Used In Current Billing Period',
            description: 'Get the number of credits that have been used in the current billing period of the membership',
          }),
        401: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
