import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    generateImage: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ generate-image',
      description: 'Generates an image.',
      method: 'POST',
      path: '/generate-image',
      body: z.object({
        userPrompt: z.string(),
        userNegativePrompt: z.string(),
        seed: z.number().nullable().optional(),
        style: z.string().nullable().optional(),
        initialImage: z.string().nullable().optional(),
        initialImageExtension: z.string().nullable().optional(),
        isGeneratedThroughApi: z.boolean().nullable().optional(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z
              .array(
                z.object({
                  id: z.string().uuid(),
                  generate_id: z.string().uuid(),
                  blip_caption: z.string(),
                  height: z.number(),
                  is_generated_through_api: z.boolean(),
                  is_picture_upload: z.boolean(),
                  is_private: z.boolean(),
                  nsfw_content_detected: z.boolean(),
                  nsfw_text_detected: z.boolean(),
                  png_s3_url: z.string(),
                  style: z.string(),
                  user_prompt: z.string(),
                  user_negative_prompt: z.string(),
                  width: z.number(),
                  updated_at: z.date(),
                  created_at: z.date(),
                }),
              )
              .optional(),
          })
          .openapi({
            title: 'Generate Image',
            description: 'Generates an image',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
