import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getImagesHistory: {
      metadata: {
        openApiTags: ['Getters'],
      },
      summary: '/ get-images-history',
      description: 'Gets a list of images that were generated by the currently authenticated user or by their IP address',
      method: 'GET',
      path: '/get-images-history',
      query: z.object({
        page: z.number().optional().default(1),
        perPage: z.number().optional().default(30),
        searchTerm: z.string().optional(),
        useOrs: z.boolean().optional(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              total: z.number(),
              images: z.array(
                z.object({
                  id: z.string().uuid(),
                  blip_caption: z.string(),
                  height: z.number(),
                  is_generated_through_api: z.boolean(),
                  is_picture_upload: z.boolean(),
                  is_private: z.boolean(),
                  nsfw_content_detected: z.boolean(),
                  nsfw_text_detected: z.boolean(),
                  png_s3_url: z.string().url(),
                  svg_s3_url: z.string().url(),
                  style: z.string(),
                  user_prompt: z.string().min(3),
                  user_negative_prompt: z.string(),
                  width: z.number(),
                  updated_at: z.date(),
                  created_at: z.date(),
                  num_ratings: z.number().nullable(),
                  rating: z.number().nullable(),
                  num_downloads: z.number().nullable(),
                  num_shares: z.number().nullable(),
                  user_rating: z
                    .object({
                      rating: z.number().min(1).max(5).openapi({
                        description: 'The calculated rating from the community, from `1` to `5`, for this image.',
                      }),
                      max_rating: z.number().min(5).max(5).openapi({
                        description: 'The maximum rating that was possible for this `rating` when it was submitted.',
                      }),
                      updated_at: z.date().openapi({
                        description: 'The date and time at which the image rating was last updated.',
                      }),
                      created_at: z.date().openapi({
                        description: 'The date and time at which the image rating was created.',
                      }),
                    })
                    .nullable(),
                }),
              ),
            }),
          })
          .openapi({
            title: 'Get Images History',
            description: "Get the current authenticated user's history images as a list",
          }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
