import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    reportImage: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ report-image',
      description: 'Reports an image.',
      method: 'POST',
      path: '/report-image',
      body: z.object({
        imageId: z.string(),
        fullName: z.string(),
        email: z.string(),
        issue: z.string(),
        description: z.string(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
          })
          .openapi({
            title: 'Report Image',
            description: 'Reports an image',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
