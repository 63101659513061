import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getFrontendInitialData: {
      metadata: {
        openApiTags: ['Getters'],
      },
      summary: '/ get-frontend-initial-data',
      description: "Gets the data necessary for the frontend's initial load",
      method: 'GET',
      path: '/get-frontend-initial-data',
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              currentUser: z
                .object({
                  id: z.string().uuid(),
                  email: z.string().email().nullable(),
                  is_email_verified: z.boolean(),
                  stripe_customer_id: z.string().nullable(),
                  first_name: z.string().nullable(),
                  middle_name: z.string().nullable(),
                  last_name: z.string().nullable(),
                  long_full_name: z.string().nullable(),
                  short_full_name: z.string().nullable(),
                  gender: z.string().nullable(),
                  phone_number: z.string().nullable(),
                  phone_number_is_verified: z.boolean().nullable(),
                  picture_url: z.string().nullable(),
                  is_deleted: z.boolean(),
                  is_admin: z.boolean(),
                  api_key: z.string().length(64).nullable(),
                  updated_at: z.date(),
                  created_at: z.date(),
                  user_credits: z
                    .object({
                      id: z.string().uuid(),
                      user_id: z.string().uuid(),
                      membership_credits: z.number(),
                      purchase_credits: z.number(),
                      updated_at: z.date(),
                      created_at: z.date(),
                    })
                    .nullable(),
                  user_membership: z
                    .object({
                      id: z.string().uuid(),
                      user_id: z.string().uuid(),
                      stripe_subscription_id: z.string().nullable(),
                      stripe_subscription_created_at: z.date().nullable(),
                      stripe_subscription_status: z.string().nullable(),
                      stripe_last_payment_error: z.string().nullable(),
                      membership_period: z.string(),
                      membership_type: z.string(),
                      num_monthly_credits: z.number(),
                      max_history: z.number(),
                      canceled_at: z.date().nullable(),
                      start_at: z.date(),
                      end_at: z.date(),
                      updated_at: z.date(),
                      created_at: z.date(),
                    })
                    .nullable(),
                })
                .nullable(),
              authProviders: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              coupons: z.array(
                z.object({
                  id: z.string().uuid(),
                  stripe_coupon_id: z.string(),
                  stripe_promo_id: z.string().nullable(),
                  name: z.string(),
                  promotion_code: z.string().nullable(),
                  is_active: z.boolean(),
                  is_public: z.boolean(),
                  amount_off: z.number().nullable(),
                  percent_off: z.string().transform(Number).nullable(),
                  currency: z.string().nullable(),
                  duration: z.string(),
                  duration_in_months: z.number().nullable(),
                  max_redemptions: z.number().nullable(),
                  expires_at: z.date().nullable(),
                  times_redeemed: z.number(),
                  first_time_transaction_only: z.boolean(),
                  minimum_purchase_price: z.number().nullable(),
                  minimum_purchase_price_currency: z.string().nullable(),
                  updated_at: z.date(),
                  created_at: z.date(),
                }),
              ),
              genders: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              membershipPeriods: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              membershipTypes: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              prices: z.array(
                z.object({
                  id: z.string().uuid(),
                  membership_type: z.string().nullable(),
                  membership_period: z.string().nullable(),
                  purchase_type: z.string(),
                  num_credits: z.number(),
                  max_history: z.number(),
                  price: z.number(),
                  currency: z.string(),
                  stripe_prod_buy_url: z.string(),
                  stripe_prod_product_id: z.string(),
                  stripe_prod_price_id: z.string(),
                  stripe_test_buy_url: z.string(),
                  stripe_test_product_id: z.string(),
                  stripe_test_price_id: z.string(),
                  updated_at: z.date(),
                  created_at: z.date(),
                }),
              ),
              purchaseTypes: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              styles: z.array(
                z.object({
                  code: z.string(),
                  example_image: z.string(),
                  sort_order: z.number(),
                }),
              ),
              verifyTypes: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
            }),
          })
          .openapi({
            title: 'Get Frontend Initial Data',
            description: 'Get all the data necessary to load the frontend initially',
          }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
