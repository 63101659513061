import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getUser: {
      metadata: {
        openApiTags: ['Getters'],
      },
      summary: '/ get-user',
      description: 'Gets a user',
      method: 'GET',
      path: '/get-user',
      query: z.object({
        email: z.string().email(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z
              .object({
                id: z.string().uuid(),
                email: z.string().email().nullable(),
                is_email_verified: z.boolean(),
                stripe_customer_id: z.string().nullable(),
                first_name: z.string().nullable(),
                middle_name: z.string().nullable(),
                last_name: z.string().nullable(),
                long_full_name: z.string().nullable(),
                short_full_name: z.string().nullable(),
                gender: z.string().nullable(),
                phone_number: z.string().nullable(),
                phone_number_is_verified: z.boolean().nullable(),
                picture_url: z.string().nullable(),
                is_deleted: z.boolean(),
                api_key: z.string().length(64).nullable(),
                updated_at: z.date(),
                created_at: z.date(),
                user_credits: z
                  .object({
                    id: z.string().uuid(),
                    user_id: z.string().uuid(),
                    membership_credits: z.number(),
                    purchase_credits: z.number(),
                    updated_at: z.date(),
                    created_at: z.date(),
                  })
                  .nullable(),
                user_membership: z
                  .object({
                    id: z.string().uuid(),
                    user_id: z.string().uuid(),
                    stripe_subscription_id: z.string().nullable(),
                    stripe_subscription_created_at: z.date().nullable(),
                    stripe_subscription_status: z.string().nullable(),
                    stripe_last_payment_error: z.string().nullable(),
                    membership_period: z.string(),
                    membership_type: z.string(),
                    num_monthly_credits: z.number(),
                    max_history: z.number(),
                    canceled_at: z.date().nullable(),
                    start_at: z.date(),
                    end_at: z.date(),
                    updated_at: z.date(),
                    created_at: z.date(),
                  })
                  .nullable(),
              })
              .optional(),
          })
          .openapi({
            title: 'Get User',
            description: 'Get a user',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        404: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
