import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getCurrentUser: {
      metadata: {
        openApiTags: ['Getters'],
        openApiSecurity: [{ BearerAuth: [] }],
      },
      summary: '/ get-current-user',
      description: 'Gets the currently authenticated user',
      method: 'GET',
      path: '/get-current-user',
      headers: z.object({
        authorization: z.string(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z
              .object({
                id: z.string().uuid(),
                email: z.string().email().nullable(),
                is_email_verified: z.boolean(),
                stripe_customer_id: z.string().nullable(),
                first_name: z.string().nullable(),
                middle_name: z.string().nullable(),
                last_name: z.string().nullable(),
                long_full_name: z.string().nullable(),
                short_full_name: z.string().nullable(),
                gender: z.string().nullable(),
                phone_number: z.string().nullable(),
                phone_number_is_verified: z.boolean().nullable(),
                picture_url: z.string().nullable(),
                is_deleted: z.boolean(),
                is_admin: z.boolean(),
                api_key: z.string().length(64).nullable(),
                updated_at: z.date(),
                created_at: z.date(),
                user_credits: z
                  .object({
                    id: z.string().uuid(),
                    user_id: z.string().uuid(),
                    membership_credits: z.number(),
                    purchase_credits: z.number(),
                    updated_at: z.date(),
                    created_at: z.date(),
                  })
                  .nullable(),
                user_membership: z
                  .object({
                    id: z.string().uuid(),
                    user_id: z.string().uuid(),
                    stripe_subscription_id: z.string().nullable(),
                    stripe_subscription_created_at: z.date().nullable(),
                    stripe_subscription_status: z.string().nullable(),
                    stripe_last_payment_error: z.string().nullable(),
                    membership_period: z.string(),
                    membership_type: z.string(),
                    num_monthly_credits: z.number(),
                    max_history: z.number(),
                    canceled_at: z.date().nullable(),
                    start_at: z.date(),
                    end_at: z.date(),
                    updated_at: z.date(),
                    created_at: z.date(),
                  })
                  .nullable(),
                user_transactions: z
                  .array(
                    z.object({
                      id: z.string().uuid(),
                      user_id: z.string().uuid(),
                      stripe_customer_id: z.string(),
                      stripe_subscription_id: z.string(),
                      stripe_product_id: z.string(),
                      stripe_price_id: z.string(),
                      stripe_coupon_id: z.string().nullable(),
                      stripe_promo_id: z.string().nullable(),
                      stripe_promotion_code: z.string().nullable(),
                      stripe_coupon_name: z.string().nullable(),
                      membership_type: z.string().nullable(),
                      membership_period: z.string().nullable(),
                      purchase_type: z.string(),
                      num_monthly_credits: z.number().nullable(),
                      selling_price: z.number().nullable(),
                      net_price: z.number().nullable(),
                      transaction_fee: z.number().nullable(),
                      tax_amount: z.number().nullable(),
                      discount_amount: z.number().nullable(),
                      currency: z.string().nullable(),
                      updated_at: z.date(),
                      created_at: z.date(),
                      user_transaction_invoices: z
                        .array(
                          z.object({
                            id: z.string().uuid(),
                            user_transaction_id: z.string().uuid(),
                            stripe_invoice_id: z.string(),
                            stripe_invoice_number: z.string(),
                            stripe_hosted_invoice_url: z.string(),
                            stripe_invoice_pdf: z.string(),
                            updated_at: z.date(),
                            created_at: z.date(),
                            user_transaction_invoice_fees: z
                              .array(
                                z.object({
                                  id: z.string().uuid(),
                                  user_transaction_invoice_id: z.string().uuid(),
                                  amount: z.number(),
                                  currency: z.string(),
                                  description: z.string(),
                                  stripe_type: z.string(),
                                  updated_at: z.date(),
                                  created_at: z.date(),
                                }),
                              )
                              .nullable(),
                          }),
                        )
                        .nullable(),
                    }),
                  )
                  .nullable(),
              })
              .nullable(),
          })
          .openapi({
            title: 'Get Current User',
            description: 'Get the current authenticated user',
          }),
        401: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
