import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    deleteUser: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ delete-user',
      description: 'Deletes the currently-authenticated user.',
      method: 'DELETE',
      path: '/delete-user',
      headers: z.object({
        authorization: z.string(),
      }),
      body: z.object({}).nullable().optional(),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
          })
          .openapi({
            title: 'Delete User',
            description: 'Deletes the currently-authenticated user',
          }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
