import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getGenders: {
      metadata: {
        openApiTags: ['Enums'],
      },
      summary: '/ get-genders',
      description: 'Gets the genders',
      method: 'GET',
      path: '/get-genders',
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.array(
              z.object({
                code: z.string(),
              }),
            ),
          })
          .openapi({
            title: 'Get Genders',
            description: 'Get the available genders',
          }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
