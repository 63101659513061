import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getStripeSession: {
      metadata: {
        openApiTags: ['Getters'],
      },
      summary: '/ get-stripe-session',
      description: 'Gets the URL to begin a Stripe checkout session for the specified priceId',
      method: 'GET',
      path: '/get-stripe-session/:priceId',
      pathParams: z.object({
        priceId: z.string().uuid(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              isUserSpecific: z.boolean(),
              checkoutUrl: z.string().url(),
            }),
          })
          .openapi({
            title: 'Get Stripe Session',
            description: 'Get the stripe session link that is tailored specifically to the currently authenticated user',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
