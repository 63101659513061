import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    stripeWebhook: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ stripe-webhook',
      description: 'Handles webhooks coming from Stripe.',
      method: 'POST',
      path: '/stripe-webhook',
      body: z.object({}).nullable().optional(),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              queueMessageId: z.string().nullable(),
              queueDeduplicationId: z.string(),
              queueGroupId: z.string(),
              queueSequenceNumber: z.string().nullable(),
            }),
          })
          .openapi({
            title: 'Stripe Webhook',
            description: 'Handles webhooks coming from Stripe',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
